import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2020 = () => (
  <Layout
    title="3M 2020 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2020 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2020 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link
                to="../3M-2021-YSC-Finalist-Presentations"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: 'c4d4bae9-0722-45a6-926b-f4f9c47bb1d1',
                    poster: '2020-finalist-thumb-awards-ceremony.png',
                    title: '3M YSC 2020 Awards Ceremony',
                    description:
                      'Presentation of the 3M Young Scientist Challenge 2020 Awards Ceremony',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'b482f132-7cc5-448f-abe2-40d95fe01fe2',
                    poster: '2020-finalist-thumb-anika-chebrolu.png',
                    title: '3M 2020 YSC Winner Presentation: Anika Chebrolu',
                    description:
                      'View 2020 Challenge Winner Anika Chebrolu’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'c1c4d5b0-9744-4d18-bc6f-5fdf711a2940',
                    poster: '2020-finalist-thumb-laasya-acharya.png',
                    title: '3M 2020 YSC Finalist Presentation: Laasya Acharya',
                    description:
                      'View 2020 Challenge Winner Laasya Acharya’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'b5b3ed27-6aa2-4f54-a771-d6cc88b26a94',
                    poster: '2020-finalist-thumb-xavier-baquero-iglesias.png',
                    title:
                      '3M 2020 YSC Finalist Presentation: Xavier Baquero-Iglesias',
                    description:
                      'View 2020 Challenge Winner Xavier Baquero-Iglesias’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '466e7010-be5c-4a27-89dd-1e7ef899631c',
                    poster: '2020-finalist-thumb-rithvik-ijju.png',
                    title: '3M 2020 YSC Finalist Presentation: Rithvik Ijju',
                    description:
                      'View 2020 Challenge Winner Rithvik Ijju’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'be5a8eb4-9bb9-45b8-95eb-ad0de7237430',
                    poster: '2020-finalist-thumb-ekansh-mittal.png',
                    title: '3M 2020 YSC Finalist Presentation: Ekansh Mittal',
                    description:
                      'View 2020 Challenge Winner Ekansh Mittal’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '4a5c4ac4-9d03-47b6-9b64-8d52d1b738b8',
                    poster: '2020-finalist-thumb-harsha-pillarisetti.png',
                    title:
                      '3M 2020 YSC Finalist Presentation: Harsha Pillarisetti',
                    description:
                      'View 2020 Challenge Winner Harsha Pillarisetti’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '1fd5183f-db15-4915-b0a7-2a5c545cfa9b',
                    poster: '2020-finalist-thumb-samhita-pokkunuri.png',
                    title:
                      '3M 2020 YSC Finalist Presentation: Samhita Pokkunuri',
                    description:
                      'View 2020 Challenge Winner Samhita Pokkunuri’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '95c967e3-0fd4-4589-bdf7-1628b5498088',
                    poster: '2020-finalist-thumb-samvrit-rao.png',
                    title: '3M 2020 YSC Finalist Presentation: Samvrit Rao',
                    description:
                      'View 2020 Challenge Winner Samvrit Rao’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '54d0152f-4c3c-493e-a916-72334f6285b1',
                    poster: '2020-finalist-thumb-kyle-tianshi.png',
                    title: '3M 2020 YSC Finalist Presentation: Kyle Tianshi',
                    description:
                      'View 2020 Challenge Winner Kyle Tianshi’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'c9bffe5a-80a3-4e97-99b0-7c03da78f5e7',
                    poster: '2020-finalist-thumb-sophia-weiner.png',
                    title: '3M 2020 YSC Finalist Presentation: Sophia Weiner',
                    description:
                      'View 2020 Challenge Winner Sophia Weiner’s Presentation.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2018-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2020 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2020;
